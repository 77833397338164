import * as React from "react"
import { useState, useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import axios from "axios"
import { toast } from "react-toastify"

import {
  Header,
  Footer,
  BottomNavbar,
  Workout,
  Dashboard,
  Exercise,
  CarouselSecondary,
  PdfDownload,
  NoAuth,
  NoAccess,
  Loader,
  UserInput,
} from "../components/index"

// utils
import mockup from "../utils/data"

// components
import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

// Context
import UserContext from "../context/UserAuth"

// utils
import { getCurrentWeek } from "../utils/dateHelper"

const IndexPage = ({ data }) => {
  const {
    user,
    userMetaData,
    isAuthenticated,
    isAccess,
    isLoading,
    isRegenerate,
    startTime,
  } = useContext(UserContext) || {
    user: null,
    userMetaData: null,
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
    isRegenerate: false,
    startTime: "2022-08-15",
  }

  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(null)

  const [currWeek, setCurrWeek] = useState(-1)
  const [weekDay, setWeekDay] = useState(-1)

  useEffect(() => {
    const current = getCurrentWeek(startTime)
    setCurrWeek(current)
    setWeekDay(current.currDay)
  }, [])

  // console.log({ currWeek, weekDay })

  useEffect(() => {
    if (user) {
      setLoading(true)
      getMealPlan(user.sub)
    }
  }, [user])

  const getMealPlan = (_id, _showAlert = false, _cb = null) => {
    axios({
      method: "get",
      url: `https://esteekeyapi.com/getdata?id=${_id}`,
      // url: `https://esteekeyapi.com/getdata?id=auth0|62e16b617113310aff10c9bc`,
    })
      .then(res => {
        setUserData(res.data.data[0])
        setLoading(false)

        if (_showAlert) {
          _cb()
          toast.success("meal plan regenerated")
        }
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const generateMealPlan = (_cal, _cb) => {
    axios({
      method: "get",
      url: `https://esteekeyapi.com/generate?id=${user.sub}&cal=${parseInt(
        _cal
      )}`,
    })
      .then(async res => {
        // setUserData(res.data.data[0])
        await timeout(2000)
        getMealPlan(user.sub, true, _cb)
        // setTimeout(() => {}, 2000)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div>
      <Helmet>
        <title>Esteekey - Dashboard</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      {isLoading && <Loader />}
      {!isAuthenticated ? (
        <NoAuth />
      ) : !isAccess ? (
        <NoAccess />
      ) : (
        <>
          <div className="mt-6 xl:mt-16 mb-12 mx-4 sm:mx-0 pt-[69px] xl:pt-0">
            <Container>
              <SubTitle
                title1="Привет"
                title2={`${userMetaData?.nickname}!`}
                text="Your daily overview is here"
              />
            </Container>
          </div>
          {!isRegenerate ? (
            <>
              {!loading ? (
                <>
                  {userData ? (
                    <>
                      {currWeek !== -1 && (
                        <Dashboard
                          list={userData.mealPlan}
                          recipeList={data.meals.nodes}
                          setUserData={setUserData}
                          userData={userData}
                          generate={generateMealPlan}
                          setCurrWeek={setCurrWeek}
                          currWeek={currWeek}
                          startDate={startTime}
                          setWeekDay={setWeekDay}
                          weekDay={weekDay}
                        />
                      )}
                    </>
                  ) : (
                    <div>
                      <Container>
                        <p className="text-xs xl:text-base px-4 sm:px-0">
                          Fill this form to get personal weekly meal plan
                        </p>
                      </Container>
                      <UserInput
                        getMealPlan={getMealPlan}
                        allergies={data?.allergens?.nodes || []}
                        types={data?.mealTypes?.nodes || []}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Container>
                  <div className="h-[calc(100vh-158px)] flex items-center justify-center">
                    <p className="text-xs xl:text-base px-4 sm:px-0">
                      Loading..
                    </p>
                  </div>
                </Container>
              )}
            </>
          ) : (
            <Container>
              <div className="h-[calc(100vh-158px)] flex items-center justify-center">
                <p className="text-xs xl:text-base font-semibold px-4 sm:px-0 my-10 text-center">
                  Regenerating meals...
                </p>
              </div>
            </Container>
          )}

          {weekDay !== -1 && (
            <div className="xl:pt-[70px]">
              <Workout
                workouts={data?.workouts?.nodes || []}
                weekDay={weekDay}
                userData={userData}
              />
            </div>
          )}
          <CarouselSecondary
            list={data.mediations.nodes}
            dark
            title1="Медитации"
            title2=""
            subtitle="Join and meditate with us"
            link="meditations"
          />
          {/* mt-6 xl:mt-24 mb-12  mx-4 sm:mx-0 */}
          <div
            id="pdf"
            className="-mt-4 xl:mt-[135px] mb-12 xl:mb-0 mx-4 sm:mx-0 "
          >
            <Container>
              <SubTitle title1="PDF" title2="файлы " text="" />
            </Container>
          </div>
          <PdfDownload list={data.pdfs.nodes || mockup.pdf_download} />
          <Exercise
            list={data.videos.nodes || mockup.videos}
            title="Видео библиотека "
          />
          <div className="mt-6 mb-12 xl:mt-52 xl:mb-[70px]  mx-4 sm:mx-0 ">
            <Container>
              <a href="https://t.me/+ZXZ-0Y8thUs3MGEy">
                <SubTitle title1="Наш" title2="Telegram" icon="/" />
              </a>
            </Container>
          </div>
        </>
      )}
      <BottomNavbar activePage="dashboard" />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query Dashboard1 {
    pdfs: allSanityPdfDownload {
      nodes {
        description
        title
        titleRU
        file {
          asset {
            url
            title
          }
        }
      }
    }
    videos: allSanityVideo(limit: 4) {
      nodes {
        link
        title
        titleRU
        tags {
          name
          nameRU
        }
      }
    }
    meals: allSanityMeal {
      nodes {
        _id
        nameRU
        name
        multiplier
        allergens {
          name
          nameRU
        }
        mealType {
          nameRU
          name
        }
        image {
          asset {
            localFile(width: 800) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 90
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        calories
        carbs
        fat
        protein
        mealTime {
          name
          nameRU
        }
        slug {
          current
        }
        recipe {
          name
          nameRU
          ingredients {
            name {
              name
              nameRU
            }
            unit {
              name
              nameRU
            }
            amount
          }
        }
      }
    }
    workouts: allSanityWorkout {
      nodes {
        _id
        day
        title
        workoutLevel {
          name
          nameRU
        }
        workoutLocation {
          name
          nameRU
        }
        allExercises {
          exerciseList {
            isCardio
            exerciseAlt {
              name
              pause
              reps
              sets
              _id
              category
              description
              exercise {
                name
                howTo
                video {
                  link
                  title
                  description
                  titleRU
                }
                nameRU
              }
              exerciseWeight {
                name
                nameRU
              }
            }
            exerciseMain {
              name
              pause
              reps
              sets
              _id
              category
              description
              exerciseWeight {
                name
                nameRU
              }
              exercise {
                name
                howTo
                video {
                  link
                  title
                  description
                  titleRU
                }
                nameRU
              }
            }
          }
        }
      }
    }
    allergens: allSanityAllergen {
      nodes {
        name
        nameRU
      }
    }
    mealTypes: allSanityMealType {
      nodes {
        name
        nameRU
      }
    }
    mediations: allSanityMeditation(limit: 5) {
      nodes {
        audio {
          asset {
            url
          }
        }
        title
        titleRU
        shortDescription
        id
        bgImage {
          asset {
            localFile(width: 800) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 90
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
